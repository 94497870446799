<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formNotaAvulsa" lazy-validation>    
      <v-card>
        <v-toolbar dark flat color="primary">
          <v-toolbar-title class="white--text">Nota Avulsa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field label="Chave da Nota" v-model="notaAvulsa.chave" mask="############################################" :rules="validarChave" required></v-text-field>          
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Valor" v-model="notaAvulsa.valor" :rules="validarValor" required></v-text-field>          
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Peso Bruto (KG)" v-model="notaAvulsa.peso_bruto" :rules="validarValor" required></v-text-field>          
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete label="Cidade" :loading="carregando" :items="cidades" :search-input.sync="busca" item-text="cidade" v-model="notaAvulsa.cidade" cache-items flat hide-no-data hide-details return-object>
                  <template slot="item" slot-scope="{ item }">
                    {{ item.cidade }} - {{ item.estado }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar()" text>Cancelar</v-btn>
          <v-btn color="success" @click="adicionarNota()">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import constantes from "@/util/constantes";
import conversor from '@/util/conversor';
import validador from '@/util/validador';
import axios from '@/util/axios';

export default {
    
    props: ['dialog', 'estado'],

    data(){
        return {
            valid: false,
            validarValor: [v => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
            validarChave: [v => (v && validador.chaveNfe(v)) || 'Chave Inválida'],
            validarCidade: [v => (v && v != '') || 'Escolha uma cidade'],
            constantes: constantes,
            carregando: false,
            busca: null,
            notaAvulsa: {
                chave: '',
                valor: '',
                peso_bruto: '',
                cidade: ''
            },
            cidades: []
        }
    },

    computed: {
        
        
    },

    watch: {

        dialog(val){
            if(val && val == true){
                this.carregarCidades();
            }
        }
    },

    methods: {

        adicionarNota(){
            if(this.$refs.formNotaAvulsa.validate()){

                this.notaAvulsa.valor = conversor.stringParaNumero(this.notaAvulsa.valor);
                this.notaAvulsa.peso_bruto = conversor.stringParaNumero(this.notaAvulsa.peso_bruto);
                this.$emit('notaAvulsa', this.notaAvulsa);
                this.limpar();
            }
        },

        fechar(){
          this.$emit('fechar');
          this.limpar();
          this.$refs.formNotaAvulsa.resetValidation();
        },

        limpar(){
            this.notaAvulsa = {
                chave: '',
                valor: '',
                peso_bruto: '',
                cidade: ''
            }
        },

        carregarCidades(){

            this.carregando = true;
            axios.get('/cidades/estado/' + this.estado)
            .then(response => {
                this.cidades = response.data;
                this.carregando = false;
            })
            .catch(erro => {
                this.carregando = false;
            });
        }
    },

    created(){

        
    }
}
</script>
