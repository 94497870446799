<template>
  <v-dialog v-model="dialog" max-width="1000px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar dark flat color="primary">
        <v-toolbar-title>Adicionar Notas no Manifesto</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-text-field flat filled rounded dense single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" :label="constantes.FILTRAR_NOTA"></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="max-height: 400px" class="pa-0">
        <v-data-table :custom-filter="filtroNotas" fixed-header v-model="notasSelecionadas" show-select item-key="_id" :headers="headers" :items="notas" :search="busca" :loading="carregando" hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td><v-simple-checkbox :ripple="false" color="primary" :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox></td>
              <td>{{ props.item.numero }}-{{props.item.serie}}</td>
              <td>{{ props.item.cliente.razao_nome }}</td>
              <td class="text-no-wrap">{{ props.item.cliente.cnpj_cpf | formatarCnpj }}</td>
              <td>{{ props.item.data_emissao | formatarData }}</td>
              <td class="text-right">{{ props.item.total_nota | formatarMoeda }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text>Cancelar</v-btn>
        <v-btn color="success" :disabled="!valido" @click="selecionarNota()">Adicionar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import constantes from "@/util/constantes";
import axios from '@/util/axios';

export default {
    
  props: ['dialog'],

  data(){
    return {
      headers: [
        { text: "Número", value: "numero", sortable: false },
        { text: "Nome do Cliente / Fornecedor", value: "razao_nome", sortable: false },
        { text: "CNPJ / CPF", value: "cnpj_cpf", sortable: false },
        { text: "Data", value: "data_emissao", sortable: false },
        { text: "Valor", value: "valor", align: "center",sortable: false },
      ],
      constantes: constantes,
      busca: "",
      notasSelecionadas: [],
      notas: [],
      carregando: false
    }
  },

  computed: {
    valido(){
      if(this.notasSelecionadas && this.notasSelecionadas.length > 0){
        return true;
      }
      else{
        return false;
      }
    }
  },

  watch: {

    dialog(val){
      if(val && val == true){
          this.carregarNotasRota();
      }
    }
  },

  methods: {

    filtroNotas(value, search, item) {
      // console.log(value, search, item)

      if (value == null && search == null && typeof value !== 'string') return false;

      if (item.chave == search) return true;
      
      if (item.numero == search) return true;

      if (item.numero + '-' + item.serie == search) return true;

      if (item.cliente.razao_nome.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) > -1) return true;

    },

    selecionarNota(){
      let notas = [];
      this.notasSelecionadas.forEach(notaSelecionada => {

        let pesoBruto = 0;
        if(notaSelecionada.transportador){
          pesoBruto = notaSelecionada.peso_bruto;
        }
        let nota = {
          chave: notaSelecionada.chave,
          valor: notaSelecionada.total_produtos,
          peso_bruto: pesoBruto,
          cidade: {
            cidade: notaSelecionada.cliente.cidade,
            codigo_cidade: notaSelecionada.cliente.codigo_cidade
          }
        }
        notas.push(nota);
      });
      this.$emit('notaSelecionada', notas);
      this.notaSelecionada = null;
    },

    fechar(){
      this.notaSelecionada = null;
      this.$emit('fechar');
    },

    carregarNotasRota(){

      this.carregando = true;

      let empresaAtual = this.$store.state.empresaAtual;
      let manif = this.$store.state.manifesto.manifestoAtual;
      let estados_rota = manif.estados_rota;
      let uf_destino = manif.uf_destino;

      let estados = [];
      estados_rota.forEach(est => {
        estados.push(est.text);
      })
      estados.push(uf_destino);

      axios.get('/' + empresaAtual._id + '/notas/query?estados=' + estados)
      .then((result) => {
        this.notas = result.data;
        this.carregando = false;
      }).catch((err) => {
        this.carregando = false;
      });
    }
  }
}
</script>
