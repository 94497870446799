<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formManifesto" lazy-validation>

      <dialog-adicionar-nota :dialog="exibirDialogNota" @notaSelecionada="adicionarNota" @fechar="exibirDialogNota = false"></dialog-adicionar-nota>
      <dialog-nota-avulsa :dialog="exibirDialogAvulsa" :estado="manifesto.uf_destino" @notaAvulsa="adicionarNota" @fechar="exibirDialogAvulsa = false"></dialog-nota-avulsa>
        
      <v-container grid-list-md fluid>

        <v-widget title="Informações do Manifesto" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Série *" v-model="manifesto.serie" v-mask="'###'" :rules="[() => !!manifesto.serie || 'Série obrigatória', v => (v && v.length === 3) || 'Formato Inválido, ex: 001']" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select label="Modalidade" :items="constantes.MODALIDADES" v-model="manifesto.modal" readonly></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Data de Emissão *" v-model="manifesto.data_emissao"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Hora da Viagem" v-model="manifesto.hora_emissao" v-mask="'##:##:##'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="constantes.TIPO_EMITENTE" v-model="manifesto.emissor.tipo_emitente" label="Tipo do Emitente"></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Data da Viagem *" v-model="manifesto.data_viagem"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Hora da Viagem" v-model="manifesto.hora_viagem" v-mask="'##:##:##'"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Carga/Descarga" class="mt-4" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-autocomplete label="Buscar Cidade de Carga *" :loading="carregando" :items="cidadesCarga" :search-input.sync="buscarCidadeCarga" item-text="cidade" v-model="cidadeCarga" cache-items flat hide-no-data hide-details return-object>
                  <template slot="item" slot-scope="{ item }">
                    {{ item.cidade }} - {{ item.estado }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field label="Mun. de Carga" v-model="manifesto.municipio_carga" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field label="UF Carga" v-model="manifesto.uf_origem" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete label="Buscar Cidade de Descarga *" :loading="carregando" :items="cidadesDescarga" :search-input.sync="buscarCidadeDescarga" item-text="cidade" v-model="cidadeDescarga" cache-items flat hide-no-data hide-details return-object>
                  <template slot="item" slot-scope="{ item }">
                    {{ item.cidade }} - {{ item.estado }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field label="Mun. de Descarga" v-model="manifesto.municipio_descarga" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field label="UF Descarga" v-model="manifesto.uf_destino" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">

              </v-col>
            </v-row>
          </div>
        </v-widget>


        <v-widget title="Estados do Percurso" class="mt-4" v-if="manifesto.uf_origem && manifesto.uf_destino && manifesto.uf_origem != manifesto.uf_destino"> <!--  -->
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-widget title="Estados Brasileiros">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3" v-for="(estado) in estados" :key="estado.value">
                        <v-btn color="warning" @click="adicionarEstadoRota(estado)">{{estado.text}}</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-col>
              <v-col cols="12" sm="6">
                <v-widget title="Estados na Rota" v-if="manifesto.estados_rota.length > 0">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3" v-for="(estado) in manifesto.estados_rota" :key="estado.value">
                        <v-btn color="success" @click="removerEstadoRota(estado)">{{estado.text}}</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Rodoviário" class="mt-4" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="RNTRC" v-model="manifesto.emissor.rntrc" v-mask="'########'"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="3">
                <v-text-field label="CIOT *" v-model="manifesto.ciot" v-mask="'############'"></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="3">
                <v-select label="Tipo Carroceria *" v-model="manifesto.veiculo.tipo_carroceria" :items="constantes.TIPO_CARROCERIA"></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select label="Tipo Rodado *" v-model="manifesto.veiculo.tipo_rodado" :items="constantes.TIPO_RODADO"></v-select>
              </v-col>
              <!-- <v-col cols="12" sm="3">
                <v-text-field label="Cód. Agend. Porto" v-model="manifesto.codAgPorto"></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="2">
                <v-text-field label="Placa *" v-model="manifesto.veiculo.placa" v-mask="'AAA-####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-select label="UF *" v-model="manifesto.veiculo.uf" :items="constantes.ESTADOS_SIGLAS"></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="RENAVAM *" v-model="manifesto.veiculo.renavam" v-mask="'###########'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Tara *" v-model="manifesto.veiculo.tara" :rules="validarValor"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Capacidade - Quilos *" v-model="manifesto.veiculo.capacidade_peso" :rules="validarValor"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Capacidade - Metros Cúbicos *" v-model="manifesto.veiculo.capacidade_volume" v-mask="'###'"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Condutores" class="mt-4" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="CPF *" v-model="manifesto.condutor.cpf" v-mask="'###.###.###-##'" :rules="validarCpf"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Nome * " v-model="manifesto.condutor.nome"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Proprietário do Veículo" class="mt-4" :temCampoObrigatorio="manifesto.emitente_nao_proprietario">
          <template v-slot:widget-header-action>
            <v-checkbox hide-details label="O emitente NÃO é o proprietário do veículo" v-model="manifesto.emitente_nao_proprietario"></v-checkbox>
          </template>
          <template v-slot:widget-content v-if="manifesto.emitente_nao_proprietario">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="CPF *" v-model="manifesto.proprietario_veiculo.cpf" v-mask="'###.###.###-##'" :rules="[manifesto.emitente_nao_proprietario ? manifesto.proprietario_veiculo.cpf && validador.cpf(manifesto.proprietario_veiculo.cpf) || 'CPF Inválido' : true]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Nome *" v-model="manifesto.proprietario_veiculo.razao_nome"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="IE *" v-model="manifesto.proprietario_veiculo.ie" :rules="validarInscricao" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="RNTRC *" v-model="manifesto.proprietario_veiculo.rntrc" v-mask="'########'" :rules="validarRntrc"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select label="Tipo Proprietário *" v-model="manifesto.proprietario_veiculo.tipo_proprietario" :items="constantes.TIPO_PROPRIETARIO"></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select label="UF *" v-model="manifesto.proprietario_veiculo.uf" :items="constantes.ESTADOS_SIGLAS"></v-select>
              </v-col>
            </v-row>
          </template>
        </v-widget>

        <v-widget title="Notas Fiscais" class="mt-4">
          <div slot="widget-header-action">
            <v-btn small outlined class="mr-4" color="success" @click="exibirDialogNota = true" :disabled="manifesto.uf_destino == ''">Notas Cadastradas<v-icon>search</v-icon></v-btn>
            <v-btn small outlined color="success" @click="exibirDialogAvulsa = true" :disabled="manifesto.uf_destino == ''">Nota Avulsa<v-icon>add</v-icon></v-btn>
          </div>
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-data-table :headers="headers" :items="manifesto.notas" :loading="carregando" hide-default-footer no-data-text="Nenhuma Nota Adicionada">
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-left">{{ props.item.chave }}</td>
                      <td class="text-right">{{ props.item.valor | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-icon @click="removerNota(props.item)">delete</v-icon>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:footer>
                    <v-row dense justify="end" class="mt-5">
                      <v-col cols="12" sm="2">
                        <v-text-field outlined label="Peso Bruto (KG)" v-model="manifesto.total_peso"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined label="Notas Fiscais" :value="totalNotas" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined label="Valor Total" :value="totalValor | formatarMoeda" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid">Cadastrar</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import moment from 'moment';
import DialogAdicionarNota from './DialogAdicionarNota';
import DialogNotaAvulsa from './DialogNotaAvulsa';
import { mask } from 'vue-the-mask';

export default {
  
  directives: {
    mask
  },

  components:{
    DialogAdicionarNota,
    DialogNotaAvulsa
  },

  data: () => ({
    valid: false,
    validador: validador,
    validarValor: [v => (v && v + "".match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
    validarCpf: [v => (v && validador.cpf(v)) || 'CPF Inválido'],
    validarInscricao: [v => !!v || 'Inscrição Inválida'],
    validarRntrc: [v => !!v || 'RNTRC Obrigatório'],
    headers: [
      { text: "Chave", value: "chave", sortable: false, align: 'left' },
      { text: "Valor", value: "valor", sortable: false, align: 'right' },
      { text: "Ações", value: "name", align: "center", sortable: false }
    ],
    exibirDialogNota: false,
    exibirDialogAvulsa: false,
    constantes: constantes,
    moment: moment,
    cidadeCarga: null,
    buscarCidadeCarga: null,
    cidadeDescarga: null,
    buscarCidadeDescarga: null,
    loading: false
  }),

  computed: {

    ...mapState('manifesto', {
      manifesto: 'manifestoAtual',
      carregando: 'carregando',
      cidadesCarga: 'cidadesCarga',
      cidadesDescarga: 'cidadesDescarga'
    }),

    ...mapGetters('manifesto', {
      estados: 'estadosDisponiveis',
      totalPeso: 'totalPeso',
      totalNotas: 'totalNotas',
      totalValor: 'totalValor'
    })
    
  },

  watch: {

    buscarCidadeCarga(val){
      let cidades = this.$store.state.manifesto.cidadesCarga;
      let carregando = this.$store.state.manifesto.carregando;
      
      if(!val || val.length < 4) return ;
      if(carregando) return ;

      this.$store.dispatch('manifesto/consultarCidadeCarga', val);
    },

    cidadeCarga(val){
      if(val){
        this.$store.commit('manifesto/setLocalCarga', val);
      }
    },

    buscarCidadeDescarga(val){
      let cidades = this.$store.state.manifesto.cidadesDescarga;
      let carregando = this.$store.state.manifesto.carregando;
      
      if(!val || val.length < 4) return ;
      if(carregando) return ;

      this.$store.dispatch('manifesto/consultarCidadeDescarga', val);
    },

    cidadeDescarga(val){
      if(val){
        this.$store.commit('manifesto/setLocalDescarga', val);
      }
    }
  },

  methods: {

    ...mapMutations('manifesto', {
      removerNota: 'removerNota',
      adicionarEstadoRota: 'adicionarEstadoRota',
      removerEstadoRota: 'removerEstadoRota'
    }),

    async salvar() {
      if (this.$refs.formManifesto.validate()) {
        try{

          let isInscricaoValida = true;
          let inscricao = `${this.manifesto.proprietario_veiculo.ie}-${this.manifesto.proprietario_veiculo.uf}`;
          if(this.manifesto.emitente_nao_proprietario){
            isInscricaoValida = validador.inscricaoEstadual(inscricao);
          }

          if(isInscricaoValida){
            if(this.manifesto.notas.length > 0){

              if(!this.manifesto.emitente_nao_proprietario){
                if(this.manifesto.emissor.rntrc != ''){
                  await this.$store.dispatch('manifesto/salvar');
                  this.$router.push('/manifestos');
                }
                else{
                  this.$store.commit('setMensagemErro', 'O Campo RNTRC passa a ser obrigatório quando o proprietário do veículo é o emissor do manifesto');
                }
              }
              else{
                await this.$store.dispatch('manifesto/salvar');
                this.$router.push('/manifestos');
              }
            }
            else{
              this.$store.commit('setMensagemErro', 'Nenhuma Nota foi adicionada ao manifesto');
            }
          }
          else{
            this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
          }
        }
        catch(erro){
          console.log(erro);
          console.log('Houve um erro');
        }
      }
    },

    adicionarNota(notas){
      this.exibirDialogNota = false;
      this.exibirDialogAvulsa = false;
      this.$store.commit('manifesto/adicionarNotas', notas);
    },

    cancelar() {
      this.$router.push('/manifestos');
    }
  },

  async created(){

    try{

      this.loading = false;
      
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
      if(estaEditando && (!this.manifesto._id || this.manifesto._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um manifesto para editar');
        this.$router.push('/manifestos');
      }
      
      if(!estaEditando){
        this.$store.commit('manifesto/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>

<style scoped>

</style>